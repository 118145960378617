<template>
  <v-app-bar app color="primary" dark :style="{ 'z-index': 999 * 9999 }">
    <v-card color="transparent" elevation="0" exact :to="{ path: '' }">
      <v-img src="@/assets/logo-header.png" max-height="40" contain></v-img>
    </v-card>
  </v-app-bar>
</template>

<script>
export default {
  name: "login_bar"
};
</script>

<style scoped></style>
